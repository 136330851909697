import Sortable from 'sortablejs';
import { onConnect } from '@sonicgarden/onconnect';

onConnect('.js-sortable', (element) => {
  const handleClass = element.dataset.sortableHandleClass;
  Sortable.create(
    element,
    {
      handle: handleClass,
      ghostClass: 'tw-bg-gray-100',
      chosenClass: 'tw-bg-yellow-100',
      onEnd: (event) => {
        const { item, newIndex } = event;
        const { url } = item.dataset;

        fetch(url, {
          method: 'PUT',
          headers: {
            'X-CSRF-Token': Rails.csrfToken(),
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            position: newIndex,
          }),
        });
      },
    },
  );
});
