import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['price', 'quantity', 'taxRate', 'totalPrice'];

  static outlets = ['total-amount'];

  connect() {
    this.updateTotal();
  }

  updateTotal() {
    const price = this.priceTarget.value;
    const quantity = this.quantityTarget.value;
    this.totalPriceTarget.textContent = this.totalPrice();

    if (this.hasTotalAmountOutlet) {
      this.totalAmountOutlet.update();
    }
  }

  totalPrice() {
    const price = this.priceTarget.value * this.quantityTarget.value;
    if (this.hasTaxRateTarget) {
      return Math.floor(price * (1 + this.taxRateTarget.value / 100));
    }
    return price;
  }
}
