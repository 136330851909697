import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['totalAmount'];

  static outlets = ['price-quantity-total'];

  connect() {
    this.update();
  }

  update() {
    this.totalAmountTarget.textContent = this.priceQuantityTotalOutlets.reduce((acc, outlet) => acc + outlet.totalPrice(), 0);
  }
}
